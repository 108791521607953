import { BrowserRouter, Route, Routes } from "react-router-dom";

//use componentes
import SchedulePageSeq from "./Containers/SchedulePageSeq/SchedulePageSeq";
import GenerateUrlPayment from "./Containers/GenerateUrlPayment/GenerateUrlPayment";



export function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/url" Component={GenerateUrlPayment} />
                <Route path="/:professional_name" Component={SchedulePageSeq} />
                <Route path="/:specialty/:professional/:amount" Component={SchedulePageSeq} />
                <Route path="/" Component={SchedulePageSeq} />
            </Routes>
        </BrowserRouter>
    );
}